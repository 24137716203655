<template>
<div class="mailchimp-form">
    <div id="mc_embed_signup">
    <form action="https://gmail.us21.list-manage.com/subscribe/post?u=c799d0a1908f79efd5ecabeb8&amp;id=3095825f0a&amp;f_id=00a6a3e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
        <div id="mc_embed_signup_scroll">
            <h2></h2>
            <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
            <div class="mc-field-group">
                <label for="mce-FNAME">First Name  <span class="asterisk">*</span>
                </label>
                <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" required>
                <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
            </div>
            <div class="mc-field-group">
                <label for="mce-LNAME">Last Name  <span class="asterisk">*</span>
                </label>
                <input type="text" value="" name="LNAME" class="required" id="mce-LNAME" required>
                <span id="mce-LNAME-HELPERTEXT" class="helper_text"></span>
            </div>
            <div class="mc-field-group">
                <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
                </label>
                <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
                <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
            </div>
            <div class="mc-field-group">
                <label for="mce-MMERGE6">What Home Service Do You Offer?  <span class="asterisk">*</span>
                </label>
                <input type="text" value="" name="MMERGE6" class="required" id="mce-MMERGE6" required>
                <span id="mce-MMERGE6-HELPERTEXT" class="helper_text">This will help us determine which templates to create</span>
            </div>
            <div id="mce-responses" class="clear foot">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
            </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c799d0a1908f79efd5ecabeb8_3095825f0a" tabindex="-1" value=""></div>
            <div class="optionalParent">
                <div class="clear foot">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
                    <p class="brandingLogo">
                        <a href="http://eepurl.com/imYXAs" title="Mailchimp - email marketing made easy and fun">
                            <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg">
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>

</div>
</template>

<script>
import '@/assets/css/mailchimp.css';

export default {
    mounted() {

    }
}
</script>