<script setup>

import { computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: [String, Number],
        required: true
    },
    type: {
        type: String,
        required: false,
        default: "text"
    },
    label: {
        type: String,
        required: false
    },
    sublabel: {
        type: String,
        required: false,
        default: ''
    },
    placeholder: {
        type: String,
        required: false
    },
    error: {
        type: Boolean,
        required: false,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<template>
<div class="wrap">
    <!-- Label -->
    <label 
        v-if="label"
        :for="name" 
        class="label"
    >{{ label }}</label>

    <!-- Input -->
    <input 
        v-model="inputValue"
        :id="name"
        :class="{
            'input': true, 
            'input-error': error
        }"
        :type="type"
        :placeholder="placeholder"
    />

    <!-- Sublabel -->
    <p v-if="sublabel != ''" class="sublabel">{{ sublabel }}</p>
</div>
</template>

<style lang="scss" scoped>
.wrap {
    width: 100%;

    .label {
        margin-bottom: 20px;
        width: 100%;
        color: #333;
        font-weight: bold;
        font-size: 14px;
    }

    .input {
        width: 100%;
        border: 1px solid #dedede;
        font-size: 16px;
        padding: 5px 10px;
    }

    .input-error {
        box-shadow: 0 0 10px var(--color-error);
    }

    .sublabel {
        margin-top: 10px;
        font-size: 12px;
        color: #999;
        line-height: 1;
    }

    .error-msg {
        font-size: 12px;
        color: var(--color-error);
        line-height: 1;
    }
}
</style>