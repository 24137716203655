import store from "..";

import mockUser from '../../mock-api/user';

const state = {
    users: null,
    currentUser: null
};

const mutations = {
    ADD_USER(state, user) {
        // Initialize users if required
        if (!state.users) {
            state.users = [];
        }

        // Add the user
        state.users.push(user);
    }
};

const actions = {
    async signup({commit, dispatch}, payload) {
        // Create the account
        let account = {
            name: payload.companyName
        };
        account = await dispatch('account/create', account, { root: true});
        
        // Create the user for the account
        let user = {
            accountId: account.id,
            email: payload.email,
            password: payload.password,
            firstName: payload.firstName,
            lastName: payload.lastName,
            phone: payload.phone
        };
        user = await mockUser.createUser(user);
        if (!user.id) {
            return;
        }

        commit('ADD_USER', user);

        return true;
    }
};

const getters = {
    users: state => state.users,
    userById: state => id => state.users.find(user => user.id == id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}