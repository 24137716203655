<script setup>
import { computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: [String, Number, Boolean],
        required: true
    },
    label: {
        type: String,
        required: false
    },
    sublabel: {
      type: String,
      required: false
    },
    trueValue: {
        type: [String, Number, Boolean],
        required: false,
        default: true
    },
    falseValue: {
        type: [String, Number, Boolean],
        required: false,
        default: false
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

</script>

<template>
    <div class="wrap">
        <p class="label">{{ label }}</p>
        <label class="switch">
            <input 
              v-model="inputValue"
              class="checkbox" 
              type="checkbox"
            />
            <span class="slider"></span>
        </label>
        <p class="sublabel">{{ sublabel }}</p>
    </div>
</template>

<style lang="scss" scoped>
.wrap {
  .label {
        margin-bottom: 5px;
        width: 100%;
        color: #333;
        font-weight: bold;
        font-size: 14px;
        white-space: nowrap;
    }

  .sublabel {
        margin-top: 10px;
        font-size: 12px;
        color: #999;
        line-height: 1;
    }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: var(--color-primary);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>