import store from "..";

import mockForm from '../../mock-api/form';

const state = {
    forms: null
};

const mutations = {
    ADD_FORM(state, form) {
        // First just verify that this form does not already exist.
        if(!store.getters['getFormById']) {
            state.forms.push(form);
        }
    },  
    SET_FORM(state, form) {
        // Initialize form if we don't have it
        if(!state.forms) {
            state.forms = [];
        }

        // Grab existing form
        const existingForm = state.forms.find(f => f.id === form.id)
        if (!existingForm) {
            // Adding a new form
            state.forms.push(form);
        } else {
            // Updating an existing form
        }
    },
    SET_FORMS(state, forms) {
        // Initialize forms if necessary
        if(!state.forms) {
            state.forms = [];
        }

        state.forms = forms;
    },
    UPDATE_FORM(state, form) {
        const index = state.forms.findIndex(f => f.id === form.id);
        if (index !== -1) {
            state.forms.splice(index, 1, form);
        }
    },
    DELETE_FORM(state, formId) {
        const formIndex = state.forms.findIndex(form => form.id == formId);

        if (formIndex == -1) {
            return;
        }

        state.forms.splice(formIndex, 1);
    }
};

const actions = {
    async createForm({ commit }, payload) {
        const form = await mockForm.createForm(payload);
        
        commit('ADD_FORM', form);

        return form;
    },  
    async getFormById({ commit }, id) {
        // Get mock data
        const form = await mockForm.getFormById(id)
        
        commit('SET_FORM', form);

        return form;
    },
    async getFormsByProjectId({ commit }, projectId) {
        // Get the forms from the api
        const forms = await mockForm.getFormsByProjectId(projectId);
        
        commit('SET_FORMS', forms);

        return forms;
    },
    async getForms({ commit }) {
        const forms = await mockForm.getForms();

        // Get mock data
        commit('SET_FORMS', forms)

        return form;
    },
    async updateForm({ commit }, form) {
        const updatedForm = await mockForm.updateFormById(form);

        if(!updatedForm) {
            return;
        }

        commit('UPDATE_FORM', updatedForm);

        return updatedForm;
    },
    async deleteFormById({ commit }, formId) {
        const deleteRes = await mockForm.deleteFormById(formId);

        commit('DELETE_FORM', formId);

        return true;
    }
};

const getters = {
    forms: state => state.forms,
    formsByProjectId: state => projectId => {
        if(!state.forms) {
            return;
        }
        return state.forms.filter(form => form.projectId == projectId);
    },
    formById: state => id => state.forms.find(form => form.id == id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}