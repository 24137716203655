<script setup>

import { computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false
    },
    sublabel: {
        type: String,
        required: false
    },
    placeholder: {
        type: String,
        required: false
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<template>
<div class="wrap">
    <label 
        v-if="label"
        :for="name" 
        class="label"
    >{{ label }}</label>
    <textarea 
        v-model="inputValue"
        :id="name"
        class="input textarea"
        :placeholder="placeholder"
    ></textarea>
    <p v-if="sublabel != ''" class="sublabel">{{ sublabel }}</p>
</div>
</template>

<style lang="scss" scoped>
.wrap {
    width: 100%;

    .label {
        margin-bottom: 20px;
        width: 100%;
        color: #333;
        font-weight: bold;
        font-size: 14px;
    }

    .sublabel {
        font-size: 12px;
        color: #999;
        line-height: 1;
    }

    .textarea {
        height: 80px;
        display: block;
        margin: 0;
    }

    .input {
        width: 100%;
        border: 1px solid #dedede;
        font-size: 16px;
        padding: 5px 10px;
        margin-bottom: 10px;
    }

    
}
</style>