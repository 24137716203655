<script setup>
</script>

<template>
<header class="header">
    <div class="logo-wrapper">
        <div class="logo">
            <img src="@/assets/images/estimator.webp" alt="Logo"/>
        </div>
    </div>
    

    <nav class="nav">
        <ul class="nav-menu">
            <li class="nav-item">
                <p>About Us</p>
            </li>
            <li class="nav-item">
                <p>Profile</p>
            </li>
        </ul>
    </nav>
</header>
</template>

<style lang="scss" scoped>
.header {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 40px;
    border-bottom: 1px solid var(--color-grey-very-light);

    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .logo {
            width: 70px;
            height: 70px;
        }
    }
    

    .nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .nav-menu {
            list-style: none;
            
            .nav-item {
                display: inline-block;
                color: var(--color-primary);
            }

            .nav-item:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}
</style>