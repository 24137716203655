<script setup>
import { ref } from 'vue';

import MailchimpSignup from '@/components/mailchimp/MailchimpSignup.vue';

const goToSignup = () => {
    const el = document.getElementById('signup');
    el.scrollIntoView({ behavior: "smooth" });
};

</script>

<template>
    <header class="header">

        <div class="logo-wrap">
            <img class="logo" src="@/assets/images/estimator-text.webp" alt="Estimator"/>
        </div>

        <!-- <nav class="header-nav">
            <router-link to="/signup">
                <button class="signup-btn">Signup</button>
            </router-link>
            <router-link to="/login">
                <button class="login-btn">Login</button>
            </router-link>
        </nav> -->

    </header>

    <section class="hero">
        <div class="hero-text">
            <p class="hero-title">Automate Your <br/>Estimate Process</p>
            <p class="hero-subtitle">Time saving tools to simplify the bidding process for any home service business</p>
            <div class="hero-btn-wrap">
                <button class="hero-btn" @click="goToSignup">Let Me Know When This App Launches</button>
            </div>
            <img 
                class="hero-screen" 
                src="@/assets/images/hero-screen.webp" 
                alt="Estimator Screenshot"
            />
        </div>
    </section>

    <section class="features">
        <article class="feature">
            <div class="feature-icon-wrap">
                <img class="feature-icon" src="@/assets/images/travel-time.webp" alt="Recover Travel Time" width="60" height="60"/>
            </div>
            <p class="feature-title">Recover Travel Time</p>
            <p class="feature-description">Helps home service businesses generate estimates quickly, without the need to travel to every customer's location, leading to improved efficiency, productivity, and profitability.</p>
        </article>

        <article class="feature">
            <div class="feature-icon-wrap">
                <img class="feature-icon" src="@/assets/images/scalable.webp" alt="Recover Travel Time" width="60" height="60"/>
            </div>
            <p class="feature-title">Customizable & Scalable</p>
            <p class="feature-description">Offers customizable templates to meet the unique needs of your service business, including the ability to adjust questions & measurements to accommodate specific requirements.</p>
        </article>

        <article class="feature">
            <div class="feature-icon-wrap">
                <img class="feature-icon" src="@/assets/images/streamline.webp" alt="Recover Travel Time" width="60" height="60"/>
            </div>
            <p class="feature-title">Streamline Operations</p>
            <p class="feature-description">Provides customers with a user-friendly form that speeds up response time, increases customer satisfaction, and eliminates manual processing, making your business more efficient.</p>
        </article>
    </section>

    <div class="split">
        <section class="promo">
            <div class="promo-overlay">
                <div class="promo-text">
                    <p class="promo-title">Be The First To Sign Up</p>
                    <p class="promo-description">Your business is about to get a whole lot more streamlined!<br/><br/>We promise to keep vour email address a secret (just between us and your Inbox), which is not to say we won't reach out to learn more about your business needs!<br/><br/>Let's get ya started.</p>
                </div>
            </div>
        </section>

        <section id="signup" class="signup">
            <img class="signup-logo" src="@/assets/images/estimator.webp" alt="Logo" width="60" height="57"/>
            <p class="signup-title">Sign up to be notified:</p>

            <mailchimp-signup/>
        </section>
    </div>

    <section class="ethos">
    <p class="ethos-title">From the fine folks who brought you:</p>
    <div class="ethos-companies">
        <article class="ethos-company">
            <img class="ethos-logo" src="@/assets/images/uservoice.webp" alt="UserVoice" width="120" height="30"/>
        </article>

        <article class="ethos-company">
            <img class="ethos-logo" src="@/assets/images/navilytics.webp" alt="Navilytics" width="120" height="41"/>
        </article>

        <article class="ethos-company">
            <img class="ethos-logo" src="@/assets/images/samename.webp" alt="SameName" width="120" height="22"/>
        </article>

        <article class="ethos-company">
            <img class="ethos-logo" src="@/assets/images/addvocate.webp" alt="Addvocate" width="120" height="24"/>
        </article>
    </div>
    </section>

    <footer class="footer">
        <div class="footer-logo-wrap">
            <img class="footer-logo" src="@/assets/images/estimator-white.webp" alt="Estimator" width="173" height="40"/>
        </div>

        <p class="footer-title">Industries Served</p>
        <p class="footer-subtitle">This is just the beginning. If you don't see your service here, let us know and we'll add it.</p>

        <div class="footer-services">
            <span class="footer-service">Accounting / Bookkeeping</span>
            <span class="footer-service">Carpet Cleaning</span>
            <span class="footer-service">Child Care</span>
            <span class="footer-service">Commercial Cleaning</span>
            <span class="footer-service">Computer Repair</span>
            <span class="footer-service">Concrete Installation</span>
            <span class="footer-service">Copywriting / Proofreading</span>
            <span class="footer-service">Custom Cabinetry</span>
            <span class="footer-service">Desk Installation</span>
            <span class="footer-service">Electrical</span>
            <span class="footer-service">Event Catering</span>
            <span class="footer-service">Event Planning</span>
            <span class="footer-service">Flooring Installation</span>
            <span class="footer-service">Gutter Cleaning / Installation</span>
            <span class="footer-service">Handyman Services</span>
            <span class="footer-service">Home Remodeling</span>
            <span class="footer-service">Home Staging</span>
            <span class="footer-service">Home-Entertainment Installs</span>
            <span class="footer-service">House Painting</span>
            <span class="footer-service">Interior Design</span>
            <span class="footer-service">Kitchen Countertop</span>
            <span class="footer-service">Lawn Care / Landscaping</span>
            <span class="footer-service">Mailing Services</span>
            <span class="footer-service">Mobile Detailing</span>
            <span class="footer-service">Mobile Locksmith</span>
            <span class="footer-service">Mobile Mechanic</span>
            <span class="footer-service">Moving Services</span>
            <span class="footer-service">Office-Support Service</span>
            <span class="footer-service">Packing / Unpacking Services</span>
            <span class="footer-service">Personal Shopper</span>
            <span class="footer-service">Pet Grooming</span>
            <span class="footer-service">Pet Sitting</span>
            <span class="footer-service">Pool Services</span>
            <span class="footer-service">Power Washing</span>
            <span class="footer-service">Professional Organization</span>
            <span class="footer-service">Residental Cleaning</span>
            <span class="footer-service">Resume Writing</span>
            <span class="footer-service">Steamstress / Tailor</span>
            <span class="footer-service">Tutoring Service</span>
            <span class="footer-service">Wedding Planner</span>
            <span class="footer-service">Window Washing</span>
            <span class="footer-service">Windshield Repair</span>
        </div>

        <div class="footer-separator"/>

        <p class="footer-copyright">&copy; Estimator Inc.</p>
    </footer>

</template>

<style lang="scss" scoped>
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
.header {
    width: 100%;
    height: 100px;
    padding: 25px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .logo-wrap {
        .logo {
            height: 55px;
        }
    }

    .header-nav {
        .signup-btn {
            border: 0;
            background-color: transparent;
            color: var(--color-primary);
            padding: 10px 20px;
            cursor: pointer;
        }

        .login-btn {
            border: 0;
            background-color: var(--color-primary);
            color: var(--color-white);
            padding: 10px 20px;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 600px) {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .header {
        .logo-wrap {
            .logo {
                height: 40px;
            }
        }
    }
}

.hero {
    background-image: linear-gradient(rgba(0,0,0,.6) 19%, rgba(255,255,255,0)), url('@/assets/images/hero-background.webp');
    background-size: cover;
    padding: 100px;

    .hero-text {
        max-width: 768px;
        margin: 0 auto;

        .hero-title {
            color: white;
            font-size: 60px;
            text-align: center;
            line-height: 1;
            font-weight: 500;
            margin-bottom: 30px;
        }

        .hero-subtitle {
            color: white;
            font-size: 20px;
            padding: 0 160px;
            font-weight: 300;
            margin-bottom: 30px;
            text-align: center;
        }

        .hero-btn-wrap {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 60px;

            .hero-btn {
                padding: 5px 60px;
                border-radius: 10px;
                border: 0;
                background-color: var(--color-primary);
                color: white;
                font-weight: 500;
                text-shadow: 1px 1px #000;
                cursor: pointer;
            }
        }
        

        .hero-screen {
            width: 100%;
            border-radius: 20px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .hero {
        padding: 20px;
        
        .hero-text {
            max-width: auto;
            .hero-title {
                font-size: 50px;
            }

            .hero-subtitle {
                font-size: 18px;
                padding: 0 120px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .hero {
        padding: 20px;
        
        .hero-text {
            max-width: auto;
            .hero-title {
                font-size: 40px;
                margin-bottom: 30px;
            }

            .hero-subtitle {
                font-size: 16px;
                margin-bottom: 30px;
                padding: 0 40px
            }

            .hero-btn-wrap {
                margin-bottom: 40px;
            }

            .hero-screen {
                border-radius: 10px;
            }
        }
    }
}

.features {
    width: 100%;
    background-color: #f5f7fa;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 40px;
    .feature {

        .feature-icon-wrap {
            margin-bottom: 10px;

            .feature-icon {
                margin: 0 auto;
            }
        }

        .feature-title {
            font-size: 26px;
            font-weight: 700;
            white-space: nowrap;
            text-align: center;
            margin-bottom: 5px;
        }

        .feature-description {
            font-size: 17px;
            text-align: center;
            font-weight: 300;
        }
    }

    .feature:not(:last-child) {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .features {
        flex-direction: column;
    }

    .feature:not(:last-child) {
        margin-right: 0;
        margin-bottom: 40px;
    }
}

.promo {
    background-image: url('@/assets/images/promo.webp');
    background-size: cover;
    width: 100%;

    .promo-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(173, 99, 8, 0.84);
        padding: 100px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .promo-text {
            .promo-title {
                color: white;
                font-size: 72px;
                line-height: 1;
                margin-bottom: 40px;
            }

            .promo-description {
                color: white;
                font-size: 18px;
                font-weight: 300;
                line-height: 1.25;
            }
        }
        
    } 
}

@media only screen and (max-width: 900px) {
    .promo {
        .promo-overlay {
            padding: 50px 25px;

            .promo-text {
                .promo-title {
                    font-size: 48px;
                }
            }
            
        }
    }
}

@media only screen and (max-width: 600px) {
    .promo {
        .promo-overlay {
            .promo-text {
                .promo-title {
                    font-size: 36px;
                }

                .promo-description {
                    font-size: 16px;
                }
            }
            
        }
    }
}

@media only screen and (max-width: 450px) {
    .promo {
        .promo-overlay {
            padding: 40px 20px;

            .promo-text {
                .promo-title {
                    font-size: 28px;
                }

                .promo-description {
                    font-size: 16px;
                }
            }
            
        }
    }
}

.signup {
    width: 100%;
    padding:  120px 40px;

    .signup-logo {
        width: 60px;
        margin-bottom: 30px;
    }

    .signup-title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 900px) {
    .signup {
        .signup-form {
            width: 80%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .signup {
        padding: 60px 30px;
        .signup-form {
            width: 100%;
        }
    }
}

.ethos {
    width: 100%;
    padding: 20px;
    background-color: #f5f7fa;

    .ethos-title {
        text-align: center;
        font-size: 16px;
        margin-bottom: 30px;
    }

    .ethos-companies {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;

        .ethos-company {
            height: 42px;
            display: flex;
            align-items: center;

            .ethos-logo {
                width: 120px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .ethos {
        padding: 40px;

        .ethos-title {
            margin-bottom: 40px;
        }

        .ethos-companies {
            flex-direction: column;

            .ethos-company:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.footer {
    background-image: linear-gradient(228deg, #455668, #1b2a3a);
    padding: 40px 60px 20px;

    .footer-logo-wrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 10px;

        .footer-logo {
            height: 40px;
        }
    }
    
    .footer-title {
        color: white;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    .footer-subtitle {
        color: white;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .footer-services {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(7, 1fr);
        row-gap: 15px;
        column-gap: 20px;
        grid-row: 7;
        margin-bottom: 80px;

        .footer-service {
            color: white;
            font-weight: 100;
            font-size: 14px;
        }
    }

    .footer-separator {
        width: 100%;
        height: 1px;
        background-color: white;
        margin-bottom: 20px;
    }

    .footer-copyright {
        color: white;
        font-weight: 100;
        font-size: 14px;
        text-align: center;
    }
    
}

@media only screen and (max-width: 900px) {
    .footer {
        padding: 20px 20px 10px;

        .footer-logo-wrap {
            justify-content: center;
        }

        .footer-title {
            text-align: center;
        }
        
        .footer-services {
            row-gap: 0;
            column-gap: 10px;
            row-gap: 8px;
            grid-template-rows: repeat(9, 1fr);
            margin-bottom: 40px;
            
            .footer-service {
                line-height: 1;
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        .footer-services {
            grid-template-rows: repeat(21, 1fr);
            
            .footer-service {
                text-align: center;
            }
        }
    }
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

@media only screen and (max-width: 900px) {
    .split {
        flex-direction: column;
    }
}
</style>