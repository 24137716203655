import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import InputFile from './components/ui/InputFile.vue';
import InputSelect from './components/ui/InputSelect.vue';
import InputSwitch from './components/ui/InputSwitch.vue';
import InputText from './components/ui/InputText.vue';
import InputTextarea from './components/ui/InputTextarea.vue';

const app = createApp(App)

app.component('InputFile', InputFile);
app.component('InputSelect', InputSelect);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('InputTextarea', InputTextarea);

app.use(store).use(router).mount('#app')
