import { createRouter, createWebHistory } from 'vue-router'
import store from './../store'

import Home from '../pages/Home.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: false,
			showSidebar: false,
			showHeader: false
		}
	},
	{ path: '/:catchAll(.*)', redirect: '/' }
	// {
	// 	path: '/signup',
	// 	name: 'signup',
	// 	component: () => import(/* webpackChunkName: "forms" */ '../pages/Signup.vue'),
	// 	meta: {
	// 		requiresAuth: false,
	// 		showSidebar: false,
	// 		showHeader: false
	// 	}
	// },
	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: () => import(/* webpackChunkName: "forms" */ '../pages/Login.vue'),
	// 	meta: {
	// 		requiresAuth: false,
	// 		showSidebar: false,
	// 		showHeader: false
	// 	}
	// },
	// {
	// 	path: '/dashboard',
	// 	name: 'dashboard',
	// 	component: () => import(/* webpackChunkName: "forms" */ '../pages/Dashboard.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 		showSidebar: true,
	// 		showHeader: true
	// 	}
	// },
	// {
	// 	path: '/projects/:id',
	// 	name: 'projects',
	// 	component: () => import(/* webpackChunkName: "form-editor" */ '../pages/Project.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 		showSidebar: true,
	// 		showHeader: true
	// 	}
	// },
	
	// {
	// 	path: '/projects/:projectId/forms/:formId/edit',
	// 	name: 'form-editor',
	// 	component: () => import(/* webpackChunkName: "form-editor" */ '../pages/FormEditor.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 		showSidebar: false,
	// 		showHeader: true
	// 	}
	// },
	// {
	// 	path: '/projects/:projectId/forms/:formId/preview',
	// 	name: 'form-preview',
	// 	component: () => import(/* webpackChunkName: "form-preview" */ '../pages/FormPreview.vue'),
	// 	meta: {
	// 		requiresAuth: true,
	// 		showSidebar: false,
	// 		showHeader: true
	// 	}
	// },
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// Require auth
// router.beforeEach((to, from, next) => {
// 	const currentUser = store.getters['login/currentUser'];
// 	const isAuthenticated = currentUser && currentUser.id != '';
	
// 	if (to.meta.requiresAuth && !isAuthenticated) {
// 		next({ name: 'login' })
// 	} else {
// 		next()
// 	}
// });
export default router
