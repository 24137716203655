import store from "..";

import mockAccount from '../../mock-api/account';

const state = {
    accounts: null
};

const mutations = {
    ADD_ACCOUNT(state, account) {
        // Initialize accounts if required
        if (!state.accounts) {
            state.accounts = [];
        }

        // Add the account
        state.accounts.push(account);
    }
};

const actions = {
    async create({commit}, account) {
        // Create the account
        const newAccount = await mockAccount.createAccount(account);

        // Verify we have a success
        if (!newAccount.id) {
            return;
        }

        // Save the account
        commit('ADD_ACCOUNT', newAccount);

        return newAccount;
    }
};

const getters = {
    accounts: state => state.accounts,
    accountById: state => id => state.accounts.find(account => account.id == id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}