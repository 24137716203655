import { createStore } from 'vuex'

import account from './modules/account';
import form from './modules/form';
import login from './modules/login';
import project from './modules/project';
import state from './modules/state';
import user from './modules/user';

export default createStore({
  modules: {
    account,
    form,
    login,
    project,
    state,
    user
  }
})
