import { v4 as uuidv4 } from 'uuid';

const projects = [
    {
        id: '814f575b-238c-4873-9a79-9071e2e83929',
        accountId: 'c8840cce-1575-4526-bac1-b703a1289f8d',
        name: "My First Project"
    },
    {
        id: '4b3c02f5-b63d-44c1-afc2-b8f4161a78f9',
        accountId: '7abab503-a9a7-41fb-9116-44ec7dcce120',
        name: "Someone elses project"
    }
];

// Create a new project
const createProject = async project => {
    console.log("mockProject createProject", project);
    // Get the project id
    project.id = uuidv4();

    // Add the project to the database
    projects.push(project);

    console.log("projects", projects);

    return project;
};

// Get project by id
const getProjectById = async id => projects.find(project => project.id == id);

// Get projects for a specific account
const getProjectsByAccountId = async accountId => projects.filter(project => project.accountId == accountId);

// Get all projects for all accounts
const getProjects = async () => projects;

// Update a project
const updateProjectById = async (id, project) => {
    // Find the project's index that we want to update
    projectIndex = projects.findIndex(p => p.id == id);
    if (projectIndex == -1) {
        return;
    }

    // Make the updated project
    const updatedProject = {...projects[projectIndex], ...project};

    // Add the project to the database
    projects[projectIndex] = updatedProject;

    return updatedProject; 
};

// Delete a project
const deleteProjectById = async id => {
    // Find the index
    const deleteIndex = projects.findIndex(project => project.id == id);
    
    if (deleteIndex == -1) {
        return;
    }

    // Delete the project
    projects.splice(deleteIndex, 1);

    return true;
}

export default {
    createProject,
    getProjectById,
    getProjectsByAccountId,
    getProjects,
    updateProjectById,
    deleteProjectById
}