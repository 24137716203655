<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import Layout from './components/layout/Layout.vue';

import SpinnerModal from './components/ui/SpinnerModal.vue';

const route = useRoute();

const store = useStore();

const showHeader = computed(() => route.meta.showHeader);

const showSidebar = computed(() => route.meta.showSidebar);

const showSpinner = computed(() => store.getters['state/showSpinner']);
</script>

<template>
    <spinner-modal v-if="showSpinner"></spinner-modal>
    
    <layout :show-header="showHeader" :show-sidebar="showSidebar">
        <router-view/>
    </layout>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

ul {
    margin: 0;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}


html {
    font-family: 'Inter', sans-serif;
}

/* CSS Variables */
:root {
    --color-primary-very-light: #b7ffdc;
    --color-primary-light: #65e6a8;
    --color-primary: #d3650d;
    --color-primary-dark: #894523;
    --color-primary-very-dark: #1e3b2d;

    --color-secondary: #f3881d;

    --color-white: white;
    --color-black: black;

    --color-grey-very-light: #dedede;
    --color-grey-light: rgb(206, 201, 201);
    --color-grey: rgb(151, 143, 156);
    --color-grey-dark: #333;
    --color-grey-very-dark: #111;

    --color-error: #e3273e;
}

/* Helper classes */
.drag-hover * {
    pointer-events: none;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0;

    
}

.row > *:not(:last-child) {
    margin-right: 20px;
}

/* Animations */

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

</style>