import { v4 as uuidv4 } from 'uuid';

// Account database
const accounts = [
    {
        id: 'c8840cce-1575-4526-bac1-b703a1289f8d',
        name: 'internal'
    },
    {
        id: '7abab503-a9a7-41fb-9116-44ec7dcce120',
        name: 'American Eagle'
    }
];

// Create a new account
const createAccount = async account => {
    // Get the account id
    account.id = uuidv4();

    // Add the account to the database
    accounts.push(account);

    console.log("accounts", accounts);

    return account;
};

// Get account by id
const getAccountById = async id => accounts.find(account => account.id == id);

// Get accounts
const getAccounts = async () => {
    return accounts;
};

// Update a account
const updateAccountById = async (id, account) => {
    // Find the account's index that we want to update
    accountIndex = accounts.findIndex(a => a.id == id);
    if (accountIndex == -1) {
        return;
    }

    // Make the updated account
    const updatedAccount = {...accounts[accountIndex], ...account};

    // Add the account to the database
    accounts[accountIndex] = updatedAccount;

    return updatedAccount; 
};

// Delete a account
const deleteAccountById = async id => {
    // Find the index
    const deleteIndex = accounts.findIndex(account => account.id == id);

    if (deleteIndex == -1) {
        return;
    }

    // Delete the account
    accounts.splice(deleteIndex, 1);

    return true;
}

export default {
    createAccount,
    getAccountById,
    getAccounts,
    updateAccountById,
    deleteAccountById
}