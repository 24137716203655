<script setup>

import { computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false
    },
    options: {
        type: Array,
        required: true
    },
    sublabel: {
        type: String,
        required: false
    },
    placeholder: {
        type: String,
        required: false
    },
    default: {
        type: String,
        required: false,
        default: ""
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
</script>

<template>
<div class="wrap">
    <label 
        v-if="label"
        :for="name" 
        class="label"
    >{{ label }}</label>
    <select
        v-model="inputValue"
        :id="name"
        class="input"
        :placeholder="placeholder"
    >
        <option
            v-for="option in options"
            :key="option.name"
            :value="option.value"
            :selected="option.default==option.value ? 'selected' : false"
        >{{ option.name }}</option>
    </select>
    <p v-if="sublabel != ''" class="sublabel">{{ sublabel }}</p>
</div>
</template>

<style lang="scss" scoped>
.wrap {
    width: 100%;

    .label {
        margin-bottom: 20px;
        width: 100%;
        color: #333;
        font-weight: bold;
        font-size: 14px;
    }

    .sublabel {
        font-size: 12px;
        color: #999;
        line-height: 1;
    }

    .input {
        width: 100%;
        border: 1px solid #dedede;
        font-size: 16px;
        margin-bottom: 10px;
        padding: 5px 10px;
    }
}
</style>