<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false,
        default: "File Upload"
    },
    sublabel: {
        type: String,
        required: false,
        default: "Select the file that you wish to upload"
    },
    accept: {
        type: String,
        required: false,
        default: "*/*"
    }
});

const emit = defineEmits(['select', 'delete']);

// Track the currently uploaded file
const file = ref(null);

// Handle the file input change
const onFileChange = event => {
    // Get the uploaded file
    const uploadedFile = event.target.files[0];
    file.value = uploadedFile;

    emit('select', uploadedFile);
}

const removeFile = () => {
    file.value = null;
    emit('delete');
}
</script>

<template>
<div class="wrap">
    <label 
        v-if="label"
        :for="name" 
        class="label"
    >{{ label }}</label>

    <div class="input-wrap">
        <label class="input-label">
            <input 
                :id="name"
                class="input"
                type="file"
                :accept="accept"
                @change="onFileChange"
            />
        </label>

        <p v-if="file" class="input-filename" >{{ file.name }}</p>

        <button 
            v-if="file" 
            class="remove-btn" 
            @click="removeFile"
        >X</button>
    </div>

    <p v-if="sublabel != ''" class="sublabel">{{ sublabel }}</p>
</div>
</template>

<style lang="scss" scoped>
.wrap {
    width: 100%;

    .label {
        margin-bottom: 20px;
        width: 100%;
        color: #333;
        font-weight: bold;
        font-size: 14px;
    }

    .sublabel {
        font-size: 12px;
        color: #999;
        line-height: 1;
    }

    // .input {
    //     width: 100%;
    //     border: 1px solid #dedede;
    //     font-size: 16px;
    //     margin-bottom: 10px;
    //     padding: 5px 10px;
    // }

    .input-wrap {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 10px;

        .input-label {
            display: inline-block;
            padding: 5px 10px;
            background-color: var(--color-primary);
            color: white;
            cursor: pointer;            

            .input {
                display: none;
            }

            .input::file-selector-button::file-selector-text {
                font-weight: bold;
                margin-left: 4px;
            }
        }

        .input-label::before {
            content: "Upload file";
        }

        .input-label::after {
            content: "\2197";
            margin-left: 4px;
        }

        .input-filename {
            flex: 1;
        }

        .remove-btn {
            border: 0;
            border-radius: 50%;
            background-color: var(--color-error);
            color: white;
            cursor: pointer;
            width: 26px;
            height: 26px;
        }
    }

    .input-wrap *:not(:last-child) {
        margin-right: 10px;
    }
}
</style>