import store from "..";

import mockAccount from '../../mock-api/account';
import mockUser from '../../mock-api/user';

const state = {
    currentAccount: null,
    currentUser: null
};

const mutations = {
    SET_CURRENT_ACCOUNT(state, account) {
        state.currentAccount = account;
    },
    SET_CURRENT_USER(state, user) {
        state.currentUser = user;
        console.log("state.currentUser", user);
    }
};

const actions = {
    async login({commit}, payload) {
        // Get the user info
        const user = await mockUser.getUserByEmail(payload.email);
        if(!user) {
            return;
        }

        // Get the account info
        const account = await mockAccount.getAccountById(user.accountId);
        if(!account) {
            return;
        }

        commit('SET_CURRENT_ACCOUNT', account);
        commit('SET_CURRENT_USER', user);

        return user;
    }
};

const getters = {
    currentUser: state => state.currentUser,
    currentAccount: state => state.currentAccount
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}