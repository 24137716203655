<template>
    <transition name="fade">
        <div class="modal">
            <div class="background"></div>

            <div class="modal-body">
                <div class="spinner"/>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.modal {
    width: 100%;
    height: 100%;
    position: absolute;

    .background {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
    }

    .modal-body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .spinner {
            border: 10px solid #f3f3f3;
            border-top: 10px solid var(--color-primary);
            border-radius: 50%;
            width: 100px;
            height: 100px;
            animation: spin .75s linear infinite;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        
    }
}
</style>