import { v4 as uuidv4 } from 'uuid';

const forms = [
    {
        id: 'bc573da9-a5d3-4645-9e4d-1608c314f73e',
        projectId: '814f575b-238c-4873-9a79-9071e2e83929',
        name: "My first form",
        properties: {
            backgroundColor: '',
            fontColor: ''
        },
        modules: []
    }
];

// Create a new form
const createForm = async payload => {
    // Get the new form id
    const newFormId = uuidv4();

    // Create a new form
    const form = {
        id: newFormId,
        projectId: payload.projectId,
        name: payload.name,
        properties: {
            backgroundColor: '',
            fontColor: ''
        },
        modules: []
    };

    // Add the form to forms
    forms.push(form);

    console.log("forms", forms);

    return form;
};

// Get a form by id
const getFormById = async id => {
    return forms.find(form => form.id == id);
};

// Get a from by project id
const getFormsByProjectId = async projectId => forms.filter(form => form.projectId == projectId);

// Get all forms
const getForms = async () => {
    return forms;
};

// Upate a form
const updateFormById = async (form) => {
    console.log("updating form req", JSON.stringify(form));
    // Find the form's index that we want to update
    const formIndex = forms.findIndex(f => f.id == form.id);
    if (formIndex == -1) {
        return;
    }

    // Make the updated form
    const updatedForm = {...forms[formIndex], ...form};

    // Add the form to the database
    forms[formIndex] = updatedForm;

    return updatedForm; 
};

// Delete a form
const deleteFormById = async id => {
    // Find the index
    const deleteIndex = forms.findIndex(form => form.id == id);

    if (deleteIndex == -1) {
        return;
    }

    // Delete the form
    forms.splice(deleteIndex, 1);

    return true;
}

export default {
    createForm,
    getFormById,
    getFormsByProjectId,
    getForms,
    updateFormById,
    deleteFormById
}