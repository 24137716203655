import { v4 as uuidv4 } from 'uuid';

// User database
const users = [
    {
        id: 'ba2fd7bc-d57a-44a5-a000-f7e90bc52793',
        accountId: 'c8840cce-1575-4526-bac1-b703a1289f8d',
        name: "Jake Schweihs",
        email: "jschweihs@gmail.com",
        role: "site-admin"
    }
];

// Create a new user
const createUser = user => {
    // Get the user id
    user.id = uuidv4();

    // Add the user to the database
    users.push(user);

    console.log("users", users);

    return user;
};

// Get user by id
const getUserById = id => users.find(user => user.id == id);

// Get user by email
const getUserByEmail = email => users.find(user => user.email == email);

// Get users
const getUsers = () => {
    return users;
};

// Update a user
const updateUserById = (id, user) => {
    // Find the user's index that we want to update
    userIndex = users.findIndex(u => u.id == id);
    if (userIndex == -1) {
        return;
    }

    // Make the updated user
    const updatedUser = {...users[userIndex], ...user};

    // Add the user to the database
    users[userIndex] = updatedUser;

    return updatedUser; 
};

// Delete a user
const deleteUserById = id => {
    // Find the index
    const deleteIndex = users.findIndex(user => user.id == id);

    if (deleteIndex == -1) {
        return;
    }

    // Delete the user
    users.splice(deleteIndex, 1);

    return true;
}

export default {
    createUser,
    getUserById,
    getUserByEmail,
    getUsers,
    updateUserById,
    deleteUserById
}