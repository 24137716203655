import store from "..";

import mockProject from '../../mock-api/project';

const state = {
    showSpinnerModal: false
};

const mutations = {
    toggleSpinnerModal(state) {
        state.showSpinnerModal = !state.showSpinnerModal;
    }
};

const actions = {};

const getters = {
    showSpinnerModal: state => state.showSpinnerModal,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}