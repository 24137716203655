<script setup>
</script>

<template>
<aside>
    <p>This is my sidebar</p>
    <p>Another item</p>
</aside>
</template>

<style lang="scss" scoped>
aside {
    flex: 0 0 200px;
    position: sticky;
    top: 100px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    background-color: var(--color-grey-light);
    padding: 10px;
    
}
</style>