import store from "..";

import mockProject from '../../mock-api/project';

const state = {
    projects: null
};

const mutations = {
    SET_PROJECT(state, project) {
        if(!state.projects) {
            state.projects = [];
        }

        if(!state.projects.find(p => p.id == project.id)) {
            state.projects.push(project);
        }
        
        console.log("state.projects", state.projects);
    },
    SET_PROJECTS(state, projects) {
        if(!state.projects) {
            state.projects = projects
        } else {
            state.projects = state.projects.concat(projects.filter(project => !state.projects.find(project2 => project2.id === project.id)));
        }
    },
    DELETE_PROJECT(state, projectId) {
        const deleteIndex = state.projects.findIndex(project => projectId == project.id);

        if (deleteIndex == -1) {
            return;
        }

        state.projects.splice(deleteIndex, 1);
    }
};

const actions = {
    async create({commit}, project) {
        // Create the project
        const newProject = await mockProject.createProject(project);

        // Verify we have a success
        if (!newProject.id) {
            return;
        }

        // Save the project
        commit('SET_PROJECT', newProject);

        return newProject;
    },
    async getProjectById({ commit }, id) {
        const project = await mockProject.getProjectById(id);

        commit ('SET_PROJECT', project);

        return project;
    },
    async getProjectsByAccountId({ commit }, accountId) {
        const projects = await mockProject.getProjectsByAccountId(accountId);

        commit ('SET_PROJECTS', projects);

        return projects;
    },
    async getAll({ commit }) {
        const projects = await mockProject.getProjects();

        commit ('SET_PROJECTS', projects);

        return projects;
    },
    async deleteProjectById({ commit }, projectId) {
        const deleteRes = await mockProject.deleteProjectById(projectId);

        commit('DELETE_PROJECT', projectId);

        return true;
    }
};

const getters = {
    projects: state => state.projects,
    projectsByAccountId: state => accountId => {
        if(!state.projects) {
            return;
        }
        console.log("state.projects", state.projects);

        return state.projects.filter(project => project.accountId == accountId)
    },
    projectById: state => id => {
        if(!state.projects) {
            return;
        }
        return state.projects.find(project => project.id == id)
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}